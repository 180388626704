<!--
 * @Descripttion: 登录页面
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-12-23 09:40:24
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-12-22 18:41:12
-->
<template>
    <div class="defaultV2-signIn" v-loading="loading" 
        element-loading-text="登录中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)">
        <!-- <star-background /> -->
        <div class="bg-img">
            <img class="img" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20231222/787b589ab75a8f140511bfeb238a32b3.jpg" />
        </div>
        <div class="logo-wrap">
            <img class="logo" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20231222/f20709c598be5cd569ed77f11aa9f259.png" />
        </div>
        <div class="sing-wrap">
            <div class="right-plan">
                <div class="sw-title">
                    <div class="title">UKOO CLOUD</div>
                    <div class="title">Management backend</div>
                </div>
                <div class="loginForm">
                    <el-form ref="form" :model="loginForm" label-position='top'>
                        <el-form-item label="">
                            <el-input v-model="loginForm.mobile" placeholder="请输入账号"></el-input>
                        </el-form-item>
                        <el-form-item label="">
                            <el-input type="password" show-password v-model="loginForm.password" placeholder="请输入密码"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="sign-btn" @click="handleLogin">登录</div>
                <!-- <div class="sign-tag">初次登录，请在小程序端设置好账号密码后进行PC登录</div> -->
            </div>
        </div>
        <!-- <div class="foot-wrap">
            <div>由东有创新 & 阿里云 提供技术服务</div>
            <el-link :underline="false" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=65010402001730" target="_blank">
                <div class="link-wrap">
                    <div class="text">Powered by Donyou innovate & aliyun</div>
                </div>
            </el-link>
        </div> -->
    </div>
</template>

<script>
import api from '@/utils/common.js';
// import StarBackground from './starBackground'
export default {
    // components: { StarBackground },
    data() {
        return {
            loginForm:{
                mobile:'',
                password:'',
            },
            codeUrl: '',
            loading: false
        }
    },
    beforeCreate: function() {
        document.getElementsByTagName('body')[0].className = 'body-bg'
    },
    mounted(){
        this.keyupSubmit();
    },
    destroyed(){
      //取消键盘监听事件
        document.onkeydown = null
    },
    methods: {
        keyupSubmit() { 
            document.onkeydown = (e) => {
                let _key = window.event.keyCode;
                if (_key === 13&&!this.clickState) {
                    this.handleLogin();
                }
            };
        },
        // 登录
        handleLogin(){
            if(this.loginForm.mobile === ''){
                this.$message.warning('请输入账号');
                return
            }
            if(this.loginForm.password === ''){
                this.$message.warning('请输入密码');
                return
            }
            let params = {
                phone: this.loginForm.mobile,
                password: this.loginForm.password,
            }
            this.loading = true;
            api.post('/service/login/login', params, (res) =>  {
                if(res.data.token){
                    localStorage.setItem('token', res.data.token);
                    this.$message.success("登录成功");
                    this.$router.replace("/");
                    this.getUserInfo()
                }
            });
            this.loading = false;
        },
        getUserInfo(){
            api.get('/service/service/get_info', {}, (res) =>  {
                localStorage.setItem('userInfo', JSON.stringify(res.data.service));
            });
        },
    }
}
</script>

<style lang="scss">
.body-bg {
  background-attachment: fixed;
  overflow: hidden;
}
@keyframes bganimation {
    0%{
        background-position: 0% 50%;
    }
    50%{
        background-position: 100% 50%;
    }
    100%{
        background-position: 0% 50%;
    }
}
.defaultV2-signIn{
    width: 100%;
    height: 100vh;
    // background-image: linear-gradient(45deg,#56ccf2,#2f80ed,#b2fefa,#0ed2f7,#6dd5fa);
    // background-size: 400%;
    // animation: bganimation 15s infinite;
    position: relative;
    .bg-img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .img{
            width: 100%;
            height: 100%;
        }
    }
    .title-wrap{
        display: flex;
        justify-content: space-between;
        padding: 30px;
        .tw-left{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .title{
                font-size: 16px;
                color: #000;
            }
            .line{
                width: 1px;
                height: 16px;
                background: #000;
                margin: 0 10px;
            }
            .tag{
                font-size: 16px;
                color: #282626;
                font-weight: normal;
            }
        }
        .tw-right{
            font-size: 16px;
            color: #000;
            text-align: right;
        }
    }
    .logo-wrap{
        position: relative;
        top: 65px;
        left: 66px;
        .logo{
            width: 60px;
            height: 66px;
        }
    }
    .sing-wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 15%;
        transform: translate(-15%,-50%);
        .right-plan{
            height: 100%;
            padding: 20px 40px 50px 40px;
            box-sizing: border-box;
            .sw-title{
                padding-bottom: 40px;
                .title{
                    font-size: 40px;
                    color: #fff;
                    font-weight: bold;
                    line-height: 65px;
                }
            }
            .loginForm{
                animation: 0.5s ease-out 0s 1 slideInFromRight;
                width: 460px;
                .el-form-item__label{
                    line-height: 30px;
                    padding:0 0 0 0;
                    color: #7D9EB5;
                }
                .el-form-item{
                    margin-bottom: 30px;
                }
                .el-input__inner{
                    border: 1px solid #000;
                    height: 50px;
                    line-height: 50px;
                    border-radius: 12px;
                }
                .el-input__inner:focus{
                    border-color: #CCDFEA
                }
                .code-wrap{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .code{
                        width: calc(100% - 200px);
                    }
                    .code-tig{
                        width: 120px;
                        height: 45px;
                        line-height: 45px;
                        text-align: center;
                        border-radius: 6px;
                        border: 1px solid #ddd;
                        overflow: hidden;
                        cursor: pointer;
                        .img{
                            width: 110%;
                            height: 110%;
                            vertical-align: bottom;
                        }
                    }
                }
            }
            .sign-btn{
                animation: 0.5s ease-out 0s 1 slideInFromRight;
                color: #fff;
                border-radius: 12px;
                width: 460px;
                height: 60px;
                line-height: 60px;
                text-align: center;
                background-color: #0068ff;
                margin-top: 50px;
                cursor: pointer;
                font-size: 20px;
                &:hover{
                    box-shadow: 0px 0px 2px #ccc;
                    background-color: #0770ff;
                }
            }
            // .sign-tag{
            //     font-size: 14px;
            //     color: #7d7d7d;
            //     padding-top: 10px;
            //     text-align: center;
            // }
        }
    }
    @keyframes slideInFromRight {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(0);
        }
    }
    .foot-wrap{
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #a9a9a9;
        font-size: 14px;
        text-align: center;
        .link-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .text{
                color: #a9a9a9 !important;
            }
        }
    }
}
</style>